<style lang="less" scoped>
    .htopImg {
        width: 100%;
        margin: auto;
        display: block;
        height: 33vw;
    }
    .width30 {
        width: 20%;
        min-width: 315px;
    }
    .pr10 {
        padding-right: 5%;
        h6 {
            color: #000;
        }
    }
    h7{
        font-size:1.2vw;
    }
    .pr5 {
        padding-right: 10px;
    }
    .pb10 {
        padding-bottom: 10px;
    }
    .cInp {
        width: 100%;
        border: solid #CCC 1px;
        border-radius: 3px;
        padding: 3px;
    }
    .borderCCC {
        border: solid #CCC 1px;
        padding: 10px;
    }
    .i1 {
        width: 50px;
        height: 50px;
    }
    .subBtn {
        display: inline-block;
        padding: 13px 40px;
        background: #007bff;
        color: #FFF;
        cursor: pointer;
        user-select: none;
        &:hover {
            opacity:0.9;
        }
    }
    .wrap {
        flex-wrap: wrap;
        padding: 10px;
    }
</style>
<template>
    <div>
        <img class="htopImg vk-bg5"  alt="">
        <br>
        <section  class="ju wrap">
            <div class="width30 pr10">
                <div class="flex">
                    <div class="pr5">
                        <svg t="1605059460686" class="icon" viewBox="0 0 1025 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3627" width="25" height="25"><path d="M49.1648 435.392C37.5168 444.096 27.6608 442.944 27.6608 442.944c-11.2 0-19.712-9.088-19.712-9.088C0.2688 426.176 0.0128 414.976 0.0128 414.976c-0.448-15.936 11.072-24 11.072-24L492.8128 28.352c19.52-14.72 36.608 0 36.608 0l481.216 362.112c5.44 4.032 9.216 11.52 9.216 11.52 4.736 10.24 1.344 19.84 1.344 19.84-3.52 13.12-14.656 17.92-14.656 17.92-16.064 8-29.376-1.472-29.376-1.472L511.1808 87.68 49.1648 435.392z" p-id="3628" fill="#19AFE0"></path><path d="M175.3088 340.416c0 0-33.728 25.216-58.432 43.968l0 454.144c0 0-0.448 49.152 3.456 68.608 0 0 4.352 50.496 38.208 73.856 0 0 21.056 18.048 72.576 20.544l528.384 0.704c0 0 67.712 1.408 100.864-23.744 0 0 21.568-12.48 34.048-46.912 0 0 8.448-22.08 10.304-56.512 0 0 0.64-3.392 0.64-23.104l3.328-465.152-61.76-46.528-0.576 537.664c0 0-0.96 33.024-12.992 47.104 0 0-17.92 15.424-38.784 16.832l-553.088 2.112c0 0-39.232 0.832-50.56-11.52 0 0-10.624-8.128-12.992-27.456 0 0-1.472-11.904-2.688-40.32L175.3088 340.416z" p-id="3629" fill="#19AFE0"></path><path d="M408.7808 681.344c0 0-0.576-10.304 3.584-11.776 0 0 3.136-1.792 9.728-1.92l9.152-0.32 156.288 0.32c0 0 9.024 0.128 12.096 1.024 0 0 7.808 0.96 11.136 7.488 0 0 2.368 5.632 2.496 13.184l0.256 253.312 29.056-0.128 0-254.592c0 0-1.344-25.984-14.272-36.544 0 0-10.304-13.12-47.552-13.12L429.0688 638.272c0 0-26.048-0.768-36.672 9.984 0 0-8.64 6.592-11.072 25.344 0 0-1.728 10.048-1.6 21.312l0 248.576c0 0 0 0 29.312-0.128l0-249.344L408.7808 681.344z" p-id="3630" fill="#19AFE0"></path></svg>
                    
                    </div>
                    <div>
                        <h7 class="pb10">{{$t('address')}}</h7>
                        <div>
                             {{$t('companyAddress')}}
                        </div>
                    </div>
                </div>
                <br>
                <div class="flex">
                    <div class="pr5">
                        <svg t="1605059955529" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4440" width="25" height="25"><path d="M755.498667 524.501333a42.624 42.624 0 0 0-60.330667 0l-68.010667 68.010667c-31.530667-9.386667-90.368-30.72-127.658666-68.010667s-58.624-96.128-68.010667-127.658666l68.010667-68.010667a42.624 42.624 0 0 0 0-60.330667l-170.666667-170.666666a42.624 42.624 0 0 0-60.330667 0l-128 128a42.496 42.496 0 0 0-12.416 27.690666c-0.64 10.666667-12.885333 263.338667 183.082667 459.306667 170.154667 170.154667 383.573333 183.424 444.928 183.424 9.344 0 14.378667-0.341333 14.378667-0.341333 10.368-0.64 20.394667-5.034667 27.690666-12.416l128-128a42.624 42.624 0 0 0 0-60.330667l-170.666666-170.666667z m-5.376 286.378667c-59.776-0.896-236.714667-16.512-378.624-158.378667-142.592-142.634667-157.226667-325.205333-158.293334-378.709333L298.666667 188.330667 409.002667 298.666667 353.834667 353.834667a42.666667 42.666667 0 0 0-11.605334 38.912c1.024 4.906667 26.069333 121.258667 96.896 192.085333s187.178667 95.872 192.085334 96.896a42.282667 42.282667 0 0 0 38.912-11.562667L725.333333 614.997333 835.669333 725.333333l-85.546666 85.546667z" fill="#19AFE0" p-id="4441"></path></svg>
                    </div>
                    <div>
                        <h7 class="pb10">+852 60994051  {{$t('mrLaw')}}</h7>
                        <div>
                            (Mon-Fri) 09:00-19:00 (GMT+8)

                        </div>
                    </div>
                </div>
                <br>
                <div class="flex">
                    <div class="pr5">
                        <svg t="1605060300354" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5290" width="25" height="25"><path d="M938.666667 853.333333 85.333333 853.333333c-47.061333 0-85.333333-38.272-85.333333-85.333333L0 256c0-47.061333 38.272-85.333333 85.333333-85.333333l853.333333 0c47.061333 0 85.333333 38.272 85.333333 85.333333l0 512C1024 815.061333 985.728 853.333333 938.666667 853.333333zM85.333333 213.333333c-23.530667 0-42.666667 19.136-42.666667 42.666667l0 512c0 23.530667 19.136 42.666667 42.666667 42.666667l853.333333 0c23.530667 0 42.666667-19.136 42.666667-42.666667L981.333333 256c0-23.530667-19.136-42.666667-42.666667-42.666667L85.333333 213.333333z" p-id="5291" fill="#19AFE0"></path><path d="M512 622.741333 137.749333 380.586667c-9.898667-6.421333-12.736-19.605333-6.336-29.504s19.605333-12.714667 29.504-6.336L512 571.925333 863.082667 344.746667c9.898667-6.378667 23.104-3.562667 29.504 6.336s3.562667 23.082667-6.336 29.504L512 622.741333z" p-id="5292" fill="#19AFE0"></path><path d="M128.021333 725.333333c-6.890667 0-13.674667-3.328-17.770667-9.493333-6.549333-9.813333-3.904-23.04 5.909333-29.589333l192-128c9.813333-6.549333 23.061333-3.904 29.589333 5.909333 6.549333 9.813333 3.904 23.04-5.909333 29.589333l-192 128C136.192 724.16 132.074667 725.333333 128.021333 725.333333z" p-id="5293" fill="#19AFE0"></path><path d="M895.978667 725.333333c-4.053333 0-8.170667-1.173333-11.818667-3.584l-192-128c-9.813333-6.549333-12.458667-19.776-5.909333-29.589333 6.528-9.813333 19.754667-12.458667 29.589333-5.909333l192 128c9.813333 6.549333 12.458667 19.776 5.909333 29.589333C909.653333 722.005333 902.869333 725.333333 895.978667 725.333333z" p-id="5294" fill="#19AFE0"></path></svg>
                    </div>
                    <div>
                        <h7 class="pb10">captainoppahkg@gmail.com</h7>
                    </div>
                </div>
            </div>
            <div class="width30">
                <input type="text" v-model="name" class="cInp" :placeholder="$t('enterName')"> <br> <br>
                <input type="text" v-model="email" class="cInp" :placeholder="$t('enterEmail')"> <br> <br>
                <input type="text" v-model="subject" class="cInp" :placeholder="$t('enterTitle')" > <br> <br>
                <textarea class="cInp" v-model="message" rows="5" :placeholder="$t('enterInfo2')"></textarea>

                <!--<div class="borderCCC sb al">
                    <label for="check1" class="al" style="margin: 0;">
                        <input type="checkbox" id="check1" v-model="check" />
                        <span style="padding-left: 10px;">I am not a robot </span>
                    </label>

                   <img class="i1" src="@/assets/images/i1.png" />!
                </div>!-->
                <br> <br>
                <a  style="color:white;" class="subBtn op" target="_blank" @click="sub">
                    {{$t('sendMsg')}}
                </a>
            </div>


        
        </section>
        <br>
        <br>
    </div>
</template>

<script>
export default {
    data () {
        return {
            name:"",
            email:"",
            sunject:"",
            message:"",
            check:"",
        }
    },
    methods:{
        sub () {
            window.open("mailto:captainoppahkg@gmail.com?subject="+this.subject+"&body="+this.message+"", '_blank');
        },
    }
}
</script>

